<template>
  <main>
    <!-- ===== Hero ===== -->
    <div class="gradient-x-overlay-sm-indigo position-relative overflow-hidden">
      <div class="container space-top-3 space-bottom-2">
        <div class="w-lg-75 text-center mx-lg-auto">
          <img class="img-fluid mb-5" src="@/assets/img/products/fiberconnect-logo.png" style="max-width:15rem">
          <h1 class="mb-3">API Connectivity for Financial Services</h1>
          <p class="lead">Leveraging Open API to boost SME & Startup efficiency with QR Code payments.</p>
          <a id="ga-product-fiberconnect-hero-cta-button" class="btn btn-success transition-3d-hover mt-3" href="https://fiberapi.com/en/" target="_blank">Get Started <i class="fas fa-external-link-alt ml-1" /></a>
        </div>
        <div class="position-relative w-90 w-md-75 mx-auto mt-6">
          <div class="position-relative z-index-2" data-aos="fade-up" data-aos-offset="-50">
            <img class="img-fluid rounded-lg" src="@/assets/img/products/fiberconnect/fiber-hero-img.png" alt="FiberConnect Portals">
          </div>
          <figure class="max-w-15rem w-100 position-absolute bottom-0 left-0" data-aos="fade-up">
            <div class="mb-n7 ml-n7">
              <img class="img-fluid" src="@/assets/svg/components/dots-5.svg">
            </div>
          </figure>
        </div>
      </div>
      <figure class="w-35 position-absolute top-0 right-0 z-index-n1 mt-n11 mr-n11">
        <img class="img-fluid" src="@/assets/svg/components/half-circle-1.svg">
      </figure>
    </div>
    <!-- ===== End Hero ===== -->

    <!-- ===== Features ===== -->
    <div class="container space-2">
      <!-- Feature 1 -->
      <div class="row align-items-lg-center mb-11">
        <div class="col-lg-6">
          <div class="mb-3 mb-lg-0">
            <h2 class="mb-3">Embody FinTech in Your Business</h2>
            <p>FiberConnect is built on top of Open API to help SME &amp; Startups  enhance their efficiency on Invoicing, Digital Payment, Accounting and Payroll through API integrations.</p>
            <h5 class="mb-3">Why FiberConnect</h5>
            <div class="media text-body font-size-1 mb-3">
              <i class="fas fa-star text-warning mt-1 mr-2" />
              <div class="media-body">
                Multiple options to fit in business use cases
              </div>
            </div>
            <div class="media text-body font-size-1 mb-3">
              <i class="fas fa-star text-warning mt-1 mr-2" />
              <div class="media-body">
                Not requiring huge technical efforts
              </div>
            </div>
            <div class="media text-body font-size-1 mb-3">
              <i class="fas fa-star text-warning mt-1 mr-2" />
              <div class="media-body">
                Real-time transaction data lookup
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6" data-aos="fade-left">
          <div class="w-90 w-md-75 w-lg-100 mx-auto">
            <img class="img-fluid" src="@/assets/img/products/fiberconnect/fiber-intro.png" alt="Embody FinTech in Your Business">
          </div>
        </div>
      </div>
      <!-- Feature 2 -->
      <div class="row align-items-lg-center mb-11">
        <div class="col-lg-6 order-lg-2">
          <div class="mb-3 mb-lg-0">
            <h2 class="mb-3">FPS Invoicing Solution</h2>
            <p>FiberConnect provides FPS invoicing service. You can generate invoices with FPS QR Code embedded and one-click issue invoices to your customer via email easily. Completed FPS transaction records will be automatically updated into portal for tracking.</p>
            <h5 class="mb-3">Popular Features</h5>
            <div class="media text-body font-size-1 mb-3">
              <i class="fas fa-star text-warning mt-1 mr-2" />
              <div class="media-body">
                Create &amp; Send Invoices with QR Code
              </div>
            </div>
            <div class="media text-body font-size-1 mb-3">
              <i class="fas fa-star text-warning mt-1 mr-2" />
              <div class="media-body">
                Real-time payment status update
              </div>
            </div>
            <div class="media text-body font-size-1 mb-3">
              <i class="fas fa-star text-warning mt-1 mr-2" />
              <div class="media-body">
                API Integration
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 order-lg-1" data-aos="fade-right">
          <div class="w-90 w-md-75 w-lg-100 mx-auto">
            <img class="img-fluid" src="@/assets/img/products/fiberconnect/fiber-invoice.png" alt="FPS Invoicing Solution">
          </div>
        </div>
      </div>
      <!-- Feature 3 Desktop view only -->
      <div class="row align-items-lg-center d-none d-lg-flex">
        <div class="col-lg-7 col-xl-6 mb-6 mb-lg-0">
          <h2 class="mb-3">Multiple Digital Payment Channels</h2>
          <p>FiberConnect supports Online &amp; Offline payment tools in various clients channels for rapid payment collection.</p>
          <ul class="nav nav-box" role="tablist">
            <li class="nav-item w-100 mx-0 mb-3">
              <a id="fiber-payment-1-tab" class="nav-link p-4 active" data-toggle="pill" href="#fiber-payment-1" role="tab" aria-controls="fiber-payment-1" aria-selected="true">
                <div class="media">
                  <figure class="w-100 max-w-6rem mt-2 mr-4">
                    <img class="img-fluid" src="@/assets/svg/icons/icon-31-green.svg">
                  </figure>
                  <div class="media-body">
                    <h4 class="mb-0">Face-to-Face Payment Collection</h4>
                    <div class="mt-2">
                      <p class="text-body mb-0">Generate a QR Code for customers to scan &amp; pay on the spot. It can be applied in physical scenarios, such as payment on delivery or payment in physical stores.</p>
                    </div>
                  </div>
                </div>
              </a>
            </li>

            <li class="nav-item w-100 mx-0 mb-3">
              <a id="fiber-payment-2-tab" class="nav-link p-4" data-toggle="pill" href="#fiber-payment-2" role="tab" aria-controls="fiber-payment-2" aria-selected="false">
                <div class="media">
                  <figure class="w-100 max-w-6rem mt-2 mr-4">
                    <img class="img-fluid" src="@/assets/svg/icons/icon-22-green.svg">
                  </figure>
                  <div class="media-body">
                    <h4 class="mb-0">Remote Payment Collection</h4>
                    <div class="mt-2">
                      <p class="text-body mb-0">Generate a payment link and share with customers via instant messaging tools. Payment link supports FPS, Alipay, WeChat Pay, etc.</p>
                    </div>
                  </div>
                </div>
              </a>
            </li>
          </ul>
        </div>
        <div class="col-lg-5 col-xl-6 align-self-lg-center">
          <div class="tab-content pr-lg-4">
            <div id="fiber-payment-1" class="tab-pane fade show active" role="tabpanel" aria-labelledby="fiber-payment-1-tab">
              <img class="img-fluid" src="@/assets/img/products/fiberconnect/fiber-f2f-payment.png" alt="Face-to-Face Payment Collection">
            </div>
            <div id="fiber-payment-2" class="tab-pane fade" role="tabpanel" aria-labelledby="fiber-payment-2-tab">
              <img class="img-fluid" src="@/assets/img/products/fiberconnect/fiber-remote-payment.png" alt="Remote Payment Collection">
            </div>
          </div>
        </div>
      </div>
      <!-- Feature 3 Mobile & Tablet view only -->
      <div class="d-lg-none">
        <div class="mb-6">
          <h2 class="mb-3">Multiple Digital Payment Channels</h2>
          <p>FiberConnect supports Online &amp; Offline payment tools in various clients channels for rapid payment collection.</p>
        </div>
        <div class="mb-6">
          <div class="media mb-3">
            <figure class="w-100 max-w-6rem mt-2 mr-4">
              <img class="img-fluid" src="@/assets/svg/icons/icon-31-green.svg">
            </figure>
            <div class="media-body">
              <h4 class="mb-0">Face-to-Face Payment Collection</h4>
              <div class="mt-2">
                <p class="text-body mb-0">Generate a QR Code for customers to scan &amp; pay on the spot. It can be applied in physical scenarios, such as payment on delivery or payment in physical stores.</p>
              </div>
            </div>
          </div>
          <div class="w-90 w-md-75 mx-auto">
            <img class="img-fluid" src="@/assets/img/products/fiberconnect/fiber-f2f-payment.png" alt="Face-to-Face Payment Collection">
          </div>
        </div>
        <div class="mb-6">
          <div class="media mb-3">
            <figure class="w-100 max-w-6rem mt-2 mr-4">
              <img class="img-fluid" src="@/assets/svg/icons/icon-22-green.svg">
            </figure>
            <div class="media-body">
              <h4 class="mb-0">Remote Payment Collection</h4>
              <div class="mt-2">
                <p class="text-body mb-0">Generate a payment link and share with customers via instant messaging tools. Payment link supports FPS, Alipay, WeChat Pay, etc.</p>
              </div>
            </div>
          </div>
          <div class="w-90 w-md-75 mx-auto">
            <img class="img-fluid" src="@/assets/img/products/fiberconnect/fiber-remote-payment.png" alt="Remote Payment Collection">
          </div>
        </div>
      </div>
    </div>
    <!-- ===== End Features ===== -->

    <!-- ===== CTA Section ===== -->
    <div class="gradient-x-overlay-sm-indigo text-center bg-img-hero" :style="{'background-image': 'url(' + require('@/assets/svg/components/fiberconnect-bg.svg') + ')'}">
      <div class="container space-2">
        <div class="mb-6">
          <h2>Get started with FiberConnect today</h2>
          <p>A better experience for your customers, fewer headaches for your team.</p>
        </div>
        <a id="ga-product-fiberconnect-main-cta-button" class="btn btn-success transition-3d-hover" href="https://fiberapi.com/en/" target="_blank">Get Started <i class="fas fa-external-link-alt ml-1" /></a>
      </div>
    </div>
    <!-- ===== End CTA Section ===== -->

    <!-- ===== Other Products Section ===== -->
    <div class="container space-2 space-lg-3">
      <div class="mb-5 text-center">
        <h2>More by beNovelty</h2>
      </div>
      <div class="row" data-aos="fade-up">
        <div class="col-12 col-md-6 col-lg-3 mb-4">
          <router-link class="card h-100 transition-3d-hover" :to="{ path: '/products/fabrixapi' }">
            <div class="card-img-top position-relative">
              <img class="card-img-top" src="@/assets/img/products/products-fabrixapi-thumbnail.jpg">
            </div>
            <div class="card-body">
              <h3>API Management Platform</h3>
              <p><strong>FabriXAPI</strong> is a one-stop API platform for providers to build and share APIs.</p>
            </div>
            <div class="card-footer border-0 pt-0">
              <span class="font-weight-bold">Learn More <i class="fas fa-angle-right fa-sm ml-1" /></span>
            </div>
          </router-link>
        </div>
        <div class="col-12 col-md-6 col-lg-3 mb-4">
          <router-link class="card h-100 transition-3d-hover" :to="{ path: '/products/openapihub' }">
            <div class="card-img-top position-relative">
              <img class="card-img-top" src="@/assets/img/products/products-openapihub-thumbnail.jpg">
            </div>
            <div class="card-body">
              <h3>Leading API Community</h3>
              <p><strong>OpenAPIHub</strong> is the leading API community with a powerful API directory and ecosystem.</p>
            </div>
            <div class="card-footer border-0 pt-0">
              <span class="font-weight-bold">Learn More <i class="fas fa-angle-right fa-sm ml-1" /></span>
            </div>
          </router-link>
        </div>
        <div class="col-12 col-md-6 col-lg-3 mb-4">
          <router-link class="card h-100 transition-3d-hover" :to="{ path: '/products/fabrixai' }">
            <div class="card-img-top position-relative">
              <img class="card-img-top" src="@/assets/img/products/products-fabrixai-thumbnail.jpg">
            </div>
            <div class="card-body">
              <h3>Low-Code AI Agent Platform</h3>
              <p><strong>FabriXAI</strong> is a low-code AI agent platform designed to simplify automation.</p>
            </div>
            <div class="card-footer border-0 pt-0">
              <span class="font-weight-bold">Learn More <i class="fas fa-angle-right fa-sm ml-1" /></span>
            </div>
          </router-link>
        </div>
        <div class="col-12 col-md-6 col-lg-3 mb-4">
          <router-link class="card h-100 transition-3d-hover" :to="{ path: '/products/taptab' }">
            <div class="card-img-top position-relative">
              <img class="card-img-top" src="@/assets/img/products/products-taptab-thumbnail.jpg">
            </div>
            <div class="card-body">
              <h3>AI Agents for Education</h3>
              <p><strong>TapTab</strong> is the AI agents designed for the education sector.</p>
            </div>
            <div class="card-footer border-0 pt-0">
              <span class="font-weight-bold">Learn More <i class="fas fa-angle-right fa-sm ml-1" /></span>
            </div>
          </router-link>
        </div>
      </div>
    </div>
    <!-- ===== End Other Products Section ===== -->
  </main>
</template>

<script>
const $ = require('jquery')
import HSGoTo from '@/assets/vendor/hs-go-to/src/js/hs-go-to'
import 'bootstrap'
import '@/assets/js/hs.core.js'
import { mapGetters } from 'vuex'
export default {
  name: 'FiberConnect',
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters([
    ])
  },
  mounted() {
    $('.js-go-to').each(function() {
      new HSGoTo($(this)).init()
    })
  },
  created() {
  },
  methods: {
  },
  metaInfo() {
    return {
      title: 'beNovelty',
      titleTemplate: 'FiberConnect | Products | %s',
      link: [
        { rel: 'canonical', href: 'https://www.benovelty.com/products/fiberconnect' }
      ],
      meta: [
        { name: 'description', content: 'Discover beNovelty\'s state-of-the-art API-First and intelligent Agentic AI Solutions, designed to elevate your enterprise\'s digital transformation.' },
        { property: 'og:site_name', content: 'beNovelty' },
        { property: 'og:title', content: 'FiberConnect | Products | beNovelty' },
        { property: 'og:description', content: 'Leveraging Open API to boost SME & Startup efficiency with QR Code payments.' },
        { property: 'og:image', content: 'https://www.benovelty.com/assets/img/thumbnails/common.jpg' },
        { property: 'og:url', content: 'https://www.benovelty.com/products/fiberconnect' },
        { name: 'twitter:card', content: 'summary_large_image' }
      ]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>

<style>
.gradient-x-overlay-sm-indigo::before {
  background-image: linear-gradient(45deg, transparent, rgba(4, 214, 214, 0.082));
  background-repeat: repeat-x;
}

.nav-box .nav-link.active {
    color: #29b8ac;
    background-color: #F8FCFC;
    box-shadow: inset 3px 0 0 #29b8ac;
    border-radius: 0px 5px 5px 0;
};
</style>
